:root {
    --shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    --whiteShadow: 0 4px 8px 0 #fafafa, 0 6px 20px 0 #fafafa;
    --darkGrey: #404040;
    --lightGrey: #696969;
    --lightWhite: #fafafa;
    --totalWhite: #fff;
    --danger: #d9534f;
    --lightBlue: rgba(150, 195, 235);
}

/* ------- <Buttons> ------- */
.main-btn {
    background-color: #404040;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.main-btn:hover {
    scale: 1.1;

    background-color: #fafafa;
    color: #404040;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.danger-btn {
    background-color: #d9534f;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.danger-btn:hover {
    scale: 1.1;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.warning-btn {
    background-color: #f0ad4e;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.warning-btn:hover {
    scale: 1.1;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.success-btn {
    background-color: #5cb85c;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.success-btn:hover {
    scale: 1.1;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.info-btn {
    background-color: #5bc0de;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.info-btn:hover {
    scale: 1.1;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.primary-btn {
    background-color: #0275d8;
    color: #fafafa;

    border: 1px solid #fafafa;
    border-radius: 5px;

    padding: 0.3rem;

    transition: all .5s ease;
}

.primary-btn:hover {
    scale: 1.1;

    border: 1px solid #404040;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* ------- </Buttons> ------- */

.form-input {
    border: none;
    border-bottom: 1px solid var(--darkGrey);

    background-color: var(--lightWhite);

    margin-bottom: 1rem;

}

.form-input:focus {
    border-bottom: 2px solid var(--darkGrey);

    outline: none;

    font-style: italic;
}

.blank-error {
    background-color: var(--danger);
    color: var(--lightWhite);

    border: 1px solid var(--lightWhite);
    border-radius: 5px;

    box-shadow: var(--shadow);
    
    margin: 1rem 0rem;
    padding: 1rem;

    text-align: center;
}