.active-projects-header {
    max-width: 100vw;
    height: 50vh;
    background-image: url('../../assets/projects.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.active-projects-container h3,
.active-projects-container h5 {
    text-align: center;
    margin-top: 2rem;
}

.active-projects {
    margin: 2rem;
}

.active-projects-single-project {
    transition: all .5s ease;
    padding: 1rem;
    margin: 1rem auto;
    width: 80%;
    border-radius: 5px;
    box-shadow: var(--shadow);
}

.active-projects-single-project:hover {
    scale: 1.02;
    box-shadow: var(--shadow);
    cursor: pointer;
}

.active-projects-single-project-title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.active-projects-single-project-body {
    display: flex;
    justify-content: space-between;
}

.active-projects-single-project-labs-title {
    font-size: large;
    font-weight: bold;
}

.active-projects-single-project-authors-supervisor {
    margin: 1rem 0rem;
}

.active-projects-single-project-authors-title,
.active-projects-single-project-authors-supervisor-title {
    font-size: medium;
}

.active-projects-single-project-single-lab,
.active-projects-single-project-single-author,
.active-projects-single-project-authors-supervisor-name {
    font-size: small;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .active-projects-header {
        height: 50vh;
        background-position: bottom;
    }
    .active-projects-single-project {
        width: 100%;
    }
    .active-projects-single-project-body {
        display: block;
    }
}