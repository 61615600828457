.announcements-container {
    padding: 3rem 1rem;
}

.announcements-container h3{
    text-align: center;
}

.announcement a{
    text-decoration: none;
    color: var(--darkGrey);
}

.announcement a:hover{
    font-style: italic;
}

.announcement-row {
    display: flex;
    border-bottom: 1px solid var(--darkGrey);
    width: 80%;
    margin: 0rem auto .5rem auto;
}

.announcement-date {
    padding-right: 1rem;
}

.announcements-container-btn {
    text-align: center;
    margin-top: 3rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .announcement-row {
        width: 90%;
    }
}