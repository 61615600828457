.project-view-project {
    margin: 5rem;
    min-height: 70vh;
}

.project-view-project h3,
.project-view-project h5 {
    text-align: center;
}

.project-view-project-labs {
    width: 80%;
    margin: 1rem auto;
    text-align: center;
}

.project-view-project-description {
    width: 80%;
    margin: 1rem auto;
    text-align: justify;
}

.project-view-project-authors {
    width: 80%;
    margin: 1rem auto;
}

.project-view-project-authors-single-author {
    margin-bottom: .5rem;
}

.project-view-project-details{
    width: 30%;
    margin: 1rem auto;

    display: flex;
    justify-content: space-around;
}