.all-staff-members-container {
    position: relative;
}

.all-staff-container-loading-height {
    min-height: 100vh;
}

.staff-members {
   margin: 3rem; 
   margin-top: 5rem;
}

.all-staff-members-container-content-btns {
    width: 100%;
}

.all-staff-members-container-content-btns button{
    width: 33.33%;
}

.staff-members h2 {
    text-align: center;
}

/* ------- <Staff Members by Lab> ------- */
.all-staff-members-labs-container {
    text-align: center;
}

.all-staff-members-labs-container select{
    background-color: var(--lightWhite);
    border: 1px solid var(--darkGrey);
    border-radius: 5px;
    padding: .5rem;
}

/* ------- </Staff Members by Lab> ------- */
