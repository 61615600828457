.news-container {
    padding: 3rem;
    min-height: 30rem;
}

.news-container h3 {
    text-align: center;
    padding-bottom: 2rem;
}

.news-row {
    width: 100%;
    min-height: fit-content;
    display: flex;
    justify-content: space-around;
    padding: 5rem  0rem;
}

.news {
    width: 40%;
    position: relative;
    transition: all 1s ease;
}

.news a {
    text-decoration: none;
    color: var(--darkGrey);
}

.news
.news-card-img img{
    width: 100%;
    height: 25rem;
    border: 1px solid var(--darkGrey);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: var(--shadow);
    transition: all .5s ease;
    object-fit: cover;
}

.news:hover
.news-card-img img{
    border-radius: 50%;
    box-shadow: none;
    border: 1px solid var(--lightWhite);
}

.news
.news-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--darkGrey);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s ease;
}

.news-card-body-date {
    text-align: center;
}

.news:hover {
    scale: 1.2;
}

.news:hover
.news-card-body {
    top: 50%;
    border: 1px solid var(--totalWhite);
    background-color: var(--totalWhite);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.news-container-btn {
    margin-top: 8rem;
    text-align: center;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .news-row {
        display: block;
        min-height: fit-content;
        padding: 0rem;
    }

    .news {
        width: 80%;
        height: fit-content;
        margin: 0 auto;
        margin-bottom: 12rem;
    }

    .news-card-body {
        text-align: center;
    }
}

/* Tablet Devices */
@media only screen and (max-width: 810px) {
    .news-row {
        display: block;
        min-height: fit-content;
        padding: 0rem;
    }

    .news {
        width: 80%;
        height: fit-content;
        margin: 0 auto;
        margin-bottom: 12rem;
    }

    .news-card-body {
        text-align: center;
    }
}