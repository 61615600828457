.student-support-useful-links-container h3 {
    text-align: center;
    margin-bottom: 2rem;
}

.useful-links-menu-row {
    display: flex;
    justify-content: center;
}

.useful-links-menu-row p {
    width: 33%;
}

.useful-links-menu-row p:hover {
    cursor: pointer;
    color: var(--lightBlue);
}

.useful-links-content-astrolavos-row {
    display: flex;
}

.useful-links-content-astrolavos-row-body {
    margin: 0rem 1rem;
    text-align: justify;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .useful-links-menu-row {
        display: block;
    }
}