.announcement-section-container {
    padding: 5rem 0rem;
    min-height: 70vh;
}

.announcement-section-container h4{
    text-align: center;
}

.announcement-section-row {
    display: flex;
}

.announcement-section-body {
    width: 80%;
    margin: 0 auto;
    padding-left: 3rem;
}

.announcement-section-body-lab {
    padding-bottom: 1rem;
}