.student-support-management-problems-container h3 {
    text-align: center;
    margin-bottom: 2rem;
}


.management-problems-menu {
    display: flex;
    justify-content: space-around;
}

.management-problems-menu p:hover{
    cursor: pointer;
    color: var(--lightBlue);
}

.management-problems-data h5,
.management-problems-performance h5,
.management-problems-knowledge h5 {
    margin-top: 2rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .management-problems-menu {
        display: block;
    }
}