.staff-member-view {
    margin: 5rem;
    min-height: 100vh;
}

.staff-member-view-row {
    display: flex;
    
}

.staff-member-view-row-img-col {
    width: 25%;
    text-align: center;
}

.staff-member-view-row-img-col img{
    width: 80%;
    margin: 0rem 1rem 1rem 1rem;
}

.staff-member-view-cv {
    text-decoration: none;
    color: var(--darkGrey);

    transition: all 1s ease;
}

.staff-member-view-cv:hover {
    text-decoration: underline;
    color: var(--darkGrey);
}

.staff-member-view-row-staff-info-col {
    width: 75%;
}

.staff-member-staff-info-social {
    display: flex;
}

.staff-member-short-bio {
    margin: 1rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .staff-member-view-row {
        display: block;
    }

    .staff-member-view-row-img-col {
        width: 100%;
    }
    
    
}