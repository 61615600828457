.news-section-container {
    padding-top: 5rem;
    min-height: 70vh;
}

.news-section-container h4{
    text-align: center;
}



.news-section-img {
    width: 80%;
    margin: 0 auto;
    padding: 3rem;

}

.news-section-img img {
    width: 100%;
    object-fit: cover
}

.news-section-body {
    width: 80%;
    margin: 0 auto;
    padding: 3rem;
}

.news-section-body-lab {
    padding-bottom: 1rem;
}