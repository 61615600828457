.vision-container-header {
    position: relative;
    background-image: url("/public/assets/vision.jpg");
    min-height: 50vh;
    background-size: cover;
}

.vision-container-header h3 {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 90%;
    font-weight: bold;
    font-size: x-large;
    color: var(--lightWhite);
}

.vision-container-body {
    margin: 5rem 2rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .vision-container-header img {
        height: 50vh;
    }
}

/* Tablet Devices */
@media only screen and (max-width: 810px) {
    .vision-container-header img {
        height: 50vh;
    }
    
}