.all-projects-container {
    padding-top: 5rem;
}

.all-projects-container h3 {
    text-align: center;

    margin-top: 2rem;
}

.all-projects {
    margin: 2rem;
}

.all-projects-single-project {
    transition: all .5s ease;

    padding: 1rem;
    margin: 1rem auto;

    width: 80%;

    border-radius: 5px;

}

.all-projects-single-project:hover {
    scale: 1.02;
    box-shadow: var(--shadow);

    cursor: pointer;
}

.all-projects-single-project-title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.all-projects-single-project-body {
    display: flex;
    justify-content: space-around;
}

.all-projects-single-project-labs-title,
.all-projects-single-project-authors-title {
    font-size: large;
    font-weight: bold;
}

.all-projects-single-project-single-lab,
.all-projects-single-project-single-author {
    font-size: small;
}

.all-projects-single-project-btn {
    text-align: center;
    margin: 1rem;
}

