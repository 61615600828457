.footer-container {
    padding-top: 1rem;
    background-image: url("/public/assets/dotted-map.png");
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
    background-color: var(--lightGrey);
    background-blend-mode: overlay;
    color: var(--lightWhite);
    animation: animatedBackground 20s linear infinite;
}

.footer-container-row-top {
    display: flex;
    justify-content: space-around;
}

.footer-row-copyrights {
    background-color: var(--lightGrey);
    background-blend-mode: overlay;
    color: var(--lightWhite);
}

.footer-row-copyrights p{
    margin-bottom: 0rem;
    text-align: center;
}

.footer-row-copyrights a{
    color: var(--lightWhite);
    text-decoration: none;
}

#contact-us-footer-img {
    box-shadow: var(--shadow);
    width: 70%;
    border-radius: 5px;
}

.footer-col h5 {
    color: var(--lightWhite);
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
    to {
        background-position: 0 0;
    }
}

.footer-col {
    width: 20%;
    display: flex;
    flex-direction: column;
}

.footer-col a {
    color: var(--lightWhite);
    text-decoration: none;
    padding-bottom: 0.5rem;
}

.footer-social-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 1rem;
}

.tuv-certificate-footer {
    width: 30%;
    border-radius: 50%;
    margin: 1rem auto;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .footer-container-row-top {
        display: block;
        text-align: center;
    }

    .footer-col {
        width: 100%;
    }

    #contact-us-footer-img {
        margin: 1rem auto;
    }
}