.user-rights-container h2 {
    padding-top: 7rem;
    text-align: center;
}

.user-rights-container-control-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.user-rights-container-control-buttons button {
    width: 40%;
}


.user-rights-users-container {
    width: 100%;
}


.single-user-container {
    padding: 1rem;
    border: 1px solid var(--darkGrey);
    border-radius: 5px;
    width: 50%;
    box-shadow: var(--shadow);
    margin: 2rem auto;
}