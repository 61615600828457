.homepage-labs-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.homepage-labs-container-title {
    text-align: center;
}

.homepage-labs-single-lab {
    position: relative;
    width: 70%;
    margin: 2rem auto;

    box-shadow: var(--shadow);
    border-radius: 5px;

    background-color: var(--darkGrey);
}

.homepage-labs-single-lab img{
    width: 100%;
    height: 30rem;
    border-radius: 5px;

    transition: all 1s ease;
}

.homepage-labs-single-lab:hover img{
    opacity: 0.3;
}

.homepage-labs-single-lab h5{
    position: absolute;
    top: 0%;

    background-color: rgba(150, 195, 235);
    color: var(--lightWhite);

    padding: 1rem .2rem;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;

    transition: all 1s ease;
}


.homepage-labs-single-lab
.homepage-labs-signle-lab-body{
    position: absolute;
    top: 15%;
    color: var(--lightWhite);

    padding: 1rem .2rem;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;

    opacity: 0;

    transition: all 1s ease;
}

.homepage-labs-single-lab:hover
.homepage-labs-signle-lab-body{
    top: 25%;
    opacity: 1;
}


.homepage-labs-signle-lab-body-btn{
    text-align: center;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {
    
    .homepage-labs-single-lab {
        height: 70vh;
    }

    .homepage-labs-single-lab img{ 
        height: 70vh;
    }

}
