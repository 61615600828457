.education-container-content-header {
    position: relative;
}

.education-container-content-header img {
    width: 100%;
    height: 100vh;
}

.education-container-content-body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--lightWhite);
    border-radius: 10px;
    box-shadow: var(--shadow);
    opacity: 0.9;
}

.education-container-content-header h3 {
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    font-weight: bold;
}

.education-container-body {
    margin: 5rem 2rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .education-container-content-body{
        width: 80%;
    }
  
}