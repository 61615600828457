.custom-form-container {
    margin: 5rem;
}

.custom-form-container-low-opacity {
    opacity: 0.5;
    pointer-events: none
}

.custom-form-container h3{
    text-align: center;
}

.custom-form {
    background-color: var(--lightWhite);
    padding: 2rem;

    border: 1px solid var(--darkGrey);
    border-radius: 10px;
    box-shadow: var(--shadow);
}

.custom-form-row {
    display: flex;
    flex-direction: column;

    margin-top: 1rem;
    margin-bottom: 1rem;

}

.form-input {
    border: none;
    border-bottom: 1px solid var(--darkGrey);

    background-color: var(--lightWhite);

    margin-bottom: 1rem;

}

.form-input:focus {
    border-bottom: 2px solid var(--darkGrey);

    outline: none;

    font-style: italic;
}


.custom-form-row-social {
    display: flex;
    flex-direction: row;
}

#custom-headmaster-container input {
    margin-right: .5rem;
    margin-bottom: .5rem;
}


#custom-img-container img{
    height: 20rem;
    width: 20rem;

    border: 1px solid var(--darkGrey);
    border-radius: 10px;
    box-shadow: var(--shadow);

    margin-top: 1rem;
}

.custom-form-row-social {
    display: flex;
    flex-direction: row;
}

.custom-social-row input{
    margin-right: 1rem;
}

.custom-form-row-social-img img {
    width: 50%;
    margin-left: .5rem;
}

.custom-social-row input{
    margin-right: 1rem;
}

.custom-form-lab-container-lab-row {
    margin-bottom: .5rem;
}

.custom-form-lab-container-lab-row input{
    margin-right: .5rem;
}

#custom-form-choose-staff-type {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.custom-form-professor-checkbox {
    margin-bottom: .5rem;
}

.custom-form-professor-checkbox input {
    margin-right: .5rem;
}

.blank-error {
    background-color: var(--danger);
    color: var(--lightWhite);

    border: 1px solid var(--lightWhite);
    border-radius: 5px;

    box-shadow: var(--shadow);
    
    margin: 1rem 0rem;
    padding: 1rem;

    text-align: center;
}

.custom-form-image-upload-container img{
    width: 10rem;
    object-fit: contain;
    border: 1px solid var(--darkGrey);
    border-radius: 10px;
    margin: 2rem auto;
}