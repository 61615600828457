.all-news-and-announcements-content {
    display: flex;
}

.all-news-and-announcements-sidebar {
    padding-top: 5rem;
    height: 100vh;
    max-height: 100vh;
    width: 20%;

    background-color: var(--darkGrey);
    color: var(--lightWhite);
}

.all-news-and-announcements-sidebar-element {
    padding-top: .75rem;

    padding-bottom: .25rem;
    padding-left: .5rem;


    border-bottom: 1px solid var(--lightWhite);

    transition: all .2s ease;
}

.all-news-and-announcements-sidebar-element:hover {
    cursor: pointer;

    box-shadow: inset 0 1px 2px 0 var(--lightWhite), inset 0 1px 3px 0 var(--lightWhite);
    border-radius: 10px;
}

.all-news-and-announcements-body {
    padding: 5rem 3rem;
    width: 80%;

    height: 100vh;

    overflow-y: scroll;
}

.all-news-and-announcements-body-row {
    margin-bottom: 1rem;
}

.all-news-and-announcements-body-row a{
    color: var(--darkGrey);
    text-decoration: none;
}

.all-news-and-announcements-title {
    font-size: large;
}

.all-news-and-announcements-lab,
.all-news-and-announcements-date {
    font-size: small;
}