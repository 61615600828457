.admin-publications-container {
    padding-top: 5rem;
}

.admin-publications-container h3{
    text-align: center;
}

.admin-publications {
    margin: 2rem;
}

.admin-single-publication-container {
    min-width: 70%;
    width: fit-content;

    margin: 1rem auto;
    padding: 1rem;

    border: 1px solid var(--darkGrey);
    border-radius: 5px;

    box-shadow: var(--shadow);
}

.admin-single-publication-doi {
    font-size: x-small;
}

.admin-single-publication-body-single-author,
.admin-single-publication-body-single-lab {
    font-size: small;
}