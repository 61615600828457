.student-support-general-material-container h3 {
    text-align: center;
    margin-bottom: 2rem;
}

.general-material-menu {
    display: flex;
    justify-content: space-around;
}

.general-material-menu p:hover{
    cursor: pointer;
    color: var(--lightBlue);
}

.general-material-content-intro,
.general-material-content-courses,
.general-material-content-articles,
.general-material-content-decision,
.general-material-content-intro p,
.general-material-content-courses p,
.general-material-content-articles p,
.general-material-content-decision p,
.general-material-content-decision h5{
    margin-top: 1rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .general-material-menu {
        display: block;
    }
}