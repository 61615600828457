.welcome-message-content {
    padding: 5rem 4rem;
}

.welcome-message-body h3 {
    text-align: center;
    margin: 3rem 0rem;
}

.welcome-message-body p {
    text-align: justify;
    padding: 0rem 4rem;
}