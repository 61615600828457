.publications-container-header-img {
    background-image: url("/public/assets/publications.png");
    filter: blur(3px);
    min-height: 50vh;
}

.publications-container-header {
    position: relative;
    border-bottom: 1px solid var(--lightWhite);
}
.publications-container h1{
    position: absolute;
    top: 85%;

    width: 100%;
    text-align: center;

    color: var(--lightWhite);
}

.publications-container-publications {
    margin: 2rem;
}

.publications-container-publications a {
    color: var(--darkGrey);
    text-decoration: none;
}

.publications-container-single-publication-container {
    display: flex;
    flex-direction: row;

    width: 80%;
    margin: 1rem auto;
    padding: 1rem;

    transition: all .5s ease;
}

.publications-container-single-publication-container:hover {
    scale: 1.05;
    border-radius: 5px;
    box-shadow: var(--shadow);

    cursor: pointer;
}

.publications-container-single-publication-body {
    width: 60%;
}


.publications-container-single-publication-doi {
    font-size: x-small;
}

.publications-container-single-publication-body-single-author,
.publications-container-single-publication-body-single-lab {
    font-size: small;
}

.publications-container-single-publication-body-authors {
    width: 40%;
}