.staff-member-row {
    display: flex; 
    margin: 2rem auto;
    border: 1px solid var(--darkGrey);
    border-radius: 10px;
    box-shadow: var(--shadow);
    width: 70%;
}

.staff-member-row-img-col {
    width: 10%;
    padding: 2rem 0rem 2rem .5rem;;
}

.staff-member-row-img-col img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    border-radius: 50%;
}

.staff-member-row-details-col {
    width: 70%;
    padding: 2rem 0rem 2rem 2rem;
}

.staff-member-row-admin-btns-col {
    width: 20%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--darkGrey);
}

.staff-member-row-admin-btns-col button {
    margin: 1rem .7rem 1rem .7rem;
}