.lab-view-publications-container {
    padding: 2rem 0rem;
}

.lab-view-publications-container h3 {
    text-align: center;
}

.lab-view-single-publication p {
    font-size: small;
}

.lab-view-publications-container a {
    color: #000;
    text-decoration: none;

   
}

.lab-view-single-publication {
    transition: all .5s ease;
}

.lab-view-single-publication:hover {
    font-style: italic;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .lab-view-single-publication p span {
        display: block;
    }
  
}