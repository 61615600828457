.publication-view-container {
    padding-top: 5rem;
}

.publication-view-publication  {
    margin: 2rem;

    min-height: 80vh;
}

.publication-view-publication h3 {
    text-align: center;
}

.publication-view-publication h5 {
    font-size: small;
    text-align: center;
    
}

.publication-view-publication-details {
    display: flex;
    width: 80%;
    margin: 1rem auto;

    justify-content: space-around;
}

.publication-view-publication-link,
.publication-view-publication-body {
    margin: 3rem 2rem 5rem 2rem;
}