.login-form-container {
    width: 60%;

    background-color: var(--lightWhite);

    border: 1px solid var(--darkGrey);
    border-radius: 5px;
    box-shadow: var(--shadow);

    margin: 15rem auto 5rem auto;
    padding: 2rem;
}

.login-form-container h1{
    text-align: center;
}