.personnel-container{
    display: flex;
    overflow: hidden;
}

.personnel-content {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 5rem 0rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .personnel-container{
        display: flex;
        overflow: hidden;
    }
    .personnel-content {
        width: 80%;
    }
}

/* Tablet Devices */
@media only screen and (max-width: 810px) {
    .personnel-content { 
        width: 70%;
    }
}
