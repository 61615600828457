.student-support-models-and-techniques-container h3 {
    text-align: center;
    margin-bottom: 2rem;
}

.models-and-techniques-menu-row {
    display: flex;
    justify-content: center;
}

.models-and-techniques-menu-row p {
    width: 33%;
}

.models-and-techniques-menu-row p:hover {
    cursor: pointer;
    color: var(--lightBlue);
}

.models-and-techniques-content-linear h5,
.models-and-techniques-content-integer-programming h5,
.models-and-techniques-content-networks h5,
.models-and-techniques-content-other-mathematical-programming h5,
.models-and-techniques-content-multicriteria h5,
.models-and-techniques-content-simulation h5 {
    margin-top: 2rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .models-and-techniques-menu-row {
        display: block;
    }
}