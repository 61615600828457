.student-support-books-container h3 {
    text-align: center;
}

.student-support-books-content-row,
.student-support-books-content-row-card {
    display: flex;
    justify-content: space-around;

    padding-top: 1rem;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .student-support-books-content-row,
.student-support-books-content-row-card {
        display: block;
    }
}