.admin-conferences-container {
    padding-top: 5rem;
}

.admin-conferences-container h3{
    text-align: center;
}

.admin-conferences {
    margin: 2rem;
}

.admin-single-conference-container {
    display: flex;

    border-bottom: 1px solid var(--darkGrey);
    margin-bottom: 1rem;
}

.admin-single-conference-body {
    width: 80%;
}

.admin-single-conference-btns {
    text-align: center;
    width: 20%;
}

.admin-single-conference-btns button{
    margin: .5rem;
}