.history-container-body {
    padding: 3rem;
    padding-top: 5rem;
}

.history-container-img,
.history-container-body h2 {
    text-align: center;
    margin: 1rem 0rem;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .history-container-img {
        width: 100%;
    }

    .history-container-img img{
        width: 100%;
    }
  
}

