.student-support-content {
    display: flex;
}

.student-support-header {
    position: relative;
}

.student-support-header img {
    width: 100%;
    height: 70vh;
}

.student-support-header h3 {
    position: absolute;
    top: 95%;

    padding-top: 2rem;
    padding-bottom: .5rem;

    width: 100%;
    text-align: center;

    color: var(--lightWhite);
    background-color: var(--lightBlue);

    font-weight: bold;
}

.student-support-body {
    padding: 5rem 2rem;

    width: 80%;
}

.student-support-content
.student-support-sidebar {
    width: 20%;
    border-right: 1px solid var(--lightBlue);
}

.student-support-sidebar ul {
    list-style-type:none;
    padding-top: 3rem;
}

.student-support-sidebar li {
    margin-bottom: 1rem;
}

.student-support-sidebar li:hover {
    cursor: pointer;
    color: var(--lightBlue);
}

.student-support-body-signature {
    text-align: right;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .student-support-header img {
        height: 50vh;
    }

    .student-support-content
    .student-support-sidebar {
        width: fit-content;
        border-right: 1px solid var(--lightBlue);
    }
  
}