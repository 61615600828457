.lab-view-new-container {
    padding-top: 5rem;
    padding-bottom: 2rem;
}
.lab-view-new-container h3{
    text-align: center;
    padding-bottom: 2rem;
}

.lab-view-new-container-row {
    display: flex;
    justify-content: space-around;
}

.lab-view-new {
    display: flex;
}

.lab-view-new-date {
    border-right: 1px solid var(--darkGrey);
    margin-right: 1rem;
    padding-right: .3rem;
}


.lab-view-new-container-announcements a,
.lab-view-new-container-news a {
    color: var(--darkGrey);
    text-decoration: none;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .lab-view-new-container-row {
        display: block;
    }

    .lab-view-new {
        margin-bottom: 2rem;
    }
  
}
