.achievements-content {
    padding-top: 5rem;
}

.achievements-content h3{
    text-align: center;
}

.achievements-content-row {
    display: flex;
}

.achievements-content-row-img {
    width: 100%;
    text-align: center;
}

.achievements-content-row-img img {
    width: 20%;
    padding: 2rem;
}

.achievements-content-row-text {
    width: 80%;
    margin: 0rem auto;
    padding: 5rem 0rem;
    text-align: justify;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .achievements-content-row-img img {
        width: 50%;
    }
}