.custom-navbar {
    width: 100%;
    border-bottom: none !important;
    display: flex;
    justify-content: center;
}

.navbar-translation-menu-btn {
    background-color: var(--totalWhite);
    color: var(--darkGrey);
    border: none;
    transition: all 2s ease;
}

.navbar-translation-menu-btn:hover {
    text-decoration: underline;
    font-style: italic;
}

#navbar-translation-menu-btn-en {
    border-right: 0.5px solid var(--darkGrey);
}

.custom-navbar-logo-container,
.custom-navbar-logo-container img {
    height: 3rem;
    padding-right: 1rem;
}

