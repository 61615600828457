.personnel-staff-member-row {
    display: flex;
    width: 100%;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--darkGrey);
}

.personnel-staff-member-img {
    width: 20%;
    text-align: center;
}


.personnel-staff-member-img img {
    height: 10rem;
    width: 10rem;
    border-radius: 10px;
}

.personnel-staff-member-body {
    width: 80%;   
}

.personnel-staff-member-body-name {
    display: flex;
    flex-direction: column;
}

/* Tablet Devices */
@media only screen and (max-width: 480px) {
    .personnel-staff-member-row {
        display: block;
    }
    .personnel-staff-member-img {
        width: 100%;
        margin: 0 auto;
    }
    .personnel-staff-member-body {
        margin-left: 1rem;
    }
}
/* Tablet Devices */
@media only screen and (max-width: 810px) { 
    .personnel-staff-member-row {
        display: block;
        margin-left: 1rem;
    }
}