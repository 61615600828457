.lab-view-body {
    margin: 3rem;
}

.lab-view-title {
    margin-top: 1rem;
    text-align: center;
}

.lab-view-description {
    margin-top: 1rem;
}

.lab-view-img {
    width: 100%;
    padding-top: 2.5rem;
}

.lab-view-img img{
    width: 100%;
    height: 25rem;
}

.lab-view-admin-btns-row {
    text-align: center;
}

.lab-view-admin-btns-row button{
    min-width: 5rem;
    margin: .5rem;
}


.lab-view-lab-members h4{
    text-align: center;
    margin: 2rem;
}

.lab-view-lab-members-single-member {
    display: flex;
    justify-content: space-around;
    margin: 2rem auto;

    width: 70%;
}

.lab-view-lab-members-single-member-card {
    width: 20%;
    height: fit-content;

    border: 1px solid var(--darkGrey);
    box-shadow: var(--shadow);
    border-radius: 10px;
}

.lab-view-lab-members-single-member-card-img img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.lab-view-lab-members-single-member-card-name {
    text-align: center;
}

.lab-view-lab-members-single-member-card-staff-type {
    text-align: center;
    font-size: small
}

.lab-view-lab-members-single-member-body {
    width: 70%;
}

.lab-view-lab-members-single-member-body-name{
    font-size: large;
}

.lab-view-lab-members-single-member-body-type {
    font-size: medium;
}

.lab-view-lab-members-single-member-body-contact,
.lab-view-lab-members-single-member-body-social-link {
    font-size: small;
}

.lab-view-lab-members-single-member-body-social-link {
    margin-right: 1rem;
}

.lab-view-lab-members-single-member-body-btn {
    margin-top: 2rem;
}

.lab-view-content-container {
    min-height: 100vh;
}

.staffMembersLoader {
    text-align: center;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    
    .lab-view-lab-members-single-member {
        display: block;
    }

    .lab-view-lab-members-single-member-card {
        width: 100%;
    }

    .lab-view-lab-members-single-member-card-img img{
        height: 20rem;
    }

    .lab-view-lab-members-single-member-body {
        width: 100%;
        text-align: center;

        margin-top: 1rem;
    }
  
}

