.data-header {
    position: relative;
    background-image: url("/public/assets/conferences.jpg");
    min-height: 50vh;
    background-size: cover;
}

.data-container h3{
    text-align: center;
    font-size: xx-large;
    position: absolute;
    top: 90%;
    width: 100%;
    color: var(--lightWhite);
}

.data {
    display: flex;
}

.data-labs {
    width: 30%;
    background-color: var(--lightBlue);
    color: var(--lightWhite);
    padding: 1rem .5rem 1rem .5rem;
}

.data-single-lab {
    margin-bottom: 1rem;
    font-size: large;
}

.data-single-lab:hover {
    cursor: pointer;
    font-style: italic;
}

.data-view {
    width: 70%;
    padding-top: 2rem;
}

.single-data-container h5{
    padding: 0rem 1rem;
    font-size: large;
}

.single-data-container p,
.single-data-container a{
    padding: 0rem 1rem;
}

.single-data-container:hover {
    cursor: pointer;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
    .data-container img {
        height: 50vh;
    }
    .data {
        display: block;
    }
    .data-labs {
        width: 100%;
        display: block;
    }
    .data-single-lab {
        border-bottom: 1px solid var(--lightWhite);
    }
    .data-view {
        width: 100%;
    }
}

/* Tablet Devices */
@media only screen and (max-width: 810px) {
    .data-container img {
        height: 50vh;
    }
}
