.homepage-more-container {
    display: flex;
    justify-content: space-around;

    padding: 5rem 4rem;
}

.homepage-more-container-col {
    display: flex;
    flex-direction: column;

    justify-content: center;

    width: 50%;
}

.homepage-more-report-link {
    color: var(--darkGrey);
}

.homepage-more-container-col button {
    margin-top: 2rem;
    width: 20%;
}


.homepage-more-container-col img {
    width: 100%;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .homepage-more-container {
        display: block;
        padding: 5rem 0rem;
    }

    .homepage-more-container-col {
        text-align: center;
        margin: 0rem auto;
    }

    .homepage-more-container-col button {
        width: 50%;
        margin-bottom: 2rem;
    }

    .homepage-more-container-col img {
        margin: 0 auto;
    }
}
