.contact-form-container {
    padding: 3rem 10rem;
}

.contact-form-container h3{
    color: var(--darkGrey);
    text-align: center;
}

.contact-form-container h2{
    color: var(--darkGrey);
    text-align: center;
}

.contact-form-inputs-container {
    display: flex;
    margin: 2rem auto;
    padding: 2rem;


    border-radius: 5px;

    box-shadow: var(--shadow);

    
}

.contact-form-inputs-left-col,
.contact-form-inputs-right-col {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 1rem auto;
}

.contact-form-inputs-left-col input {
    width: 70%;
    margin-bottom: 1rem;
    padding: .5rem;
}

.contact-form-inputs-right-col textarea {
    margin-bottom: 1rem;
    padding: .5rem;
}

.contact-form-inputs-right-col button {
    margin: 0rem auto;
    padding: 0.5rem;

    width: 20%;


}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .contact-form-container {
        padding: 3rem 1rem;
    }

    .contact-form-inputs-container {
        display: block;    
        
    }

    .contact-form-inputs-left-col,
    .contact-form-inputs-right-col {
        width: 100%;
    }
}