.homepage-stats-container {
    background-color: var(--lightBlue);
    color: var(--lightWhite);

    padding: 5rem 0rem;
}

.homepage-stats-container h3,
.homepage-stats-container h5 {
    text-align: center;
    color: var(--lightWhite);
}

.homepage-stats-counters{
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 2rem auto;
}

.homepage-stats-counters h5 {
    text-align: center;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

    .homepage-stats-counters {
        display: block;
        text-align: center;
    }

    .homepage-stats-members-counter,
    .homepage-stats-labs-counter,
    .homepage-stats-conferences-counter,
    .homepage-stats-projects-counter {
        margin-bottom: 2rem;
    }
  
}
