.news-loading-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--lightWhite);
    border: 1px solid var(--darkGrey);
    border-radius: 5px;
    padding: 2rem 3rem;
    box-shadow: var(--shadow);
}